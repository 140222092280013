import { Component, OnInit } from '@angular/core';
declare function callFroalaEditorWithAllOption(): any;
@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html'
})
export class PrivateLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    callFroalaEditorWithAllOption();
  }
}

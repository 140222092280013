import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BroadcastService } from '../../core/services/broadcast.service'
import { ToasterModule, ToasterService, BodyOutputType } from "angular2-toaster";
import { Router } from '@angular/router';

import { AuthService } from '../services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private toasterService: ToasterService;
  constructor(
    private authService: AuthService,
    private router: Router,
    toasterService: ToasterService,
    private _broadcastService: BroadcastService
  ) {
    this.toasterService = toasterService;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError(err => {
        let e = {
          message: err.error.message,
          data: err.error.data
        }

        if (err instanceof HttpErrorResponse) {
          // do error handling here
          if (err.status === 401) {
            // redirect to login
            this.toasterService.clear(); this.toasterService.pop("error", e.message, e.data);
            this.router.navigateByUrl('login');
          } else if (err.status === 400) {
            if (e.message == "Bad Request") {
              this.toasterService.clear(); this.toasterService.pop("error", e.message, e.data.message)
            } else if (e.message == "missingparameters") {
              let messageDetails = [];
              for (let x in e.data) {
                messageDetails.push(e.data[x].msg);
              }
              this.toasterService.clear(); this.toasterService.pop("error", e.message, messageDetails.join(','))
            }
            else {
              this.toasterService.clear(); this.toasterService.pop("error", e.message, e.data)
            }
          } else if (err.status === 0) {
            this.toasterService.clear(); this.toasterService.pop("error", "error", err.message)
          }
          else if (err.status === 404) {
            this.toasterService.clear(); this.toasterService.pop("error", "error", err.message)
          }
          else {
            this.toasterService.clear(); this.toasterService.pop("error", e.message, e.data)
            // handle other cases
          }
        }
        this._broadcastService.broadcastLoader(false);
        return of(null);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NotFoundComponent } from './core/components';
import { PublicLayoutComponent, PrivateLayoutComponent } from './shared';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: './modules/auth/login/login.module#LoginModule'
      },
      {
        path: 'invoice',
        loadChildren: './modules/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'transfer-invoice',
        loadChildren: './modules/transfer-invoice/invoice.module#InvoiceModule'
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ]
  },

  // {
  //   path: '',
  //   component: PublicLayoutComponent,
  //   children: [
  //     {
  //       path: 'registration',
  //       loadChildren: './modules/auth/registration/registration.module#RegistrationModule'
  //     },
  //     {
  //       path: '',
  //       redirectTo: 'login',
  //       pathMatch: 'full'
  //     }
  //   ]
  // },

  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'userenrollment',
        loadChildren: './modules/user-enrollment/user-enrollment.module#UserEnrollmentModule'
      }
    ]
  },
  {
    path: 'enrollmentdetails',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'course/:id',
        loadChildren: './modules/user-enrollment-detail/user-enrollment-detail.module#UserEnrollmentDetailModule'
      },
      {
        path: 'package/:id',
        loadChildren: './modules/user-enrollment-detail/user-enrollment-detail.module#UserEnrollmentDetailModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'profile',
        loadChildren: './modules/profile/profile.module#ProfileModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'notification',
        loadChildren: './modules/notification/notification.module#NotificationModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'orders',
        loadChildren: './modules/orders/orders.module#OrdersModule'
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'subject',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-subject/create-subject.module#CreateSubjectModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-subject/create-subject.module#CreateSubjectModule'
      }
    ]
  },
  {
    path: 'subject',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-subject/manage-subject.module#ManageSubjectModule'
      }
    ]
  },
  {
    path: 'book',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-order/manage-order.module#ManageOrderModule'
      },
      {
        path: 'create',
        loadChildren: './modules/create-course/create-course.module#CreateCourseModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-course/create-course.module#CreateCourseModule'
      }
    ]
  },
  {
    path: 'payment',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-payment/manage-payment.module#ManagePaymentModule'
      },
      {
        path: 'transfer',
        loadChildren: './modules/manage-payment-transfer/manage-payment-transfer.module#ManagePaymentTransferModule'
      }
    ]
  },
  {
    path: 'previousquestion',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-previous-question/create-previous-question.module#CreatePreviousQuestionModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-previous-question/create-previous-question.module#CreatePreviousQuestionModule'
      }
    ]
  },
  {
    path: 'previousquestion',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-previous-question/manage-previous-question.module#ManagePreviousQuestionModule'
      }
    ]
  },
  {
    path: 'accesscode',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-accesscode/manage-accesscode.module#ManageAccessCodeModule'
      }
    ]
  },
  {
    path: 'accesscode',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-accesscode/create-accesscode.module#CreateAccessCodeModule'
      },
      {
        path: 'edit/:id',
        loadChildren: './modules/create-accesscode/create-accesscode.module#CreateAccessCodeModule'
      }
    ]
  },
  {
    path: 'sort',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'course',
        loadChildren: './modules/manage-sorting/manage-sorting.module#ManageSortingModule'
      },
      {
        path: 'chapter/:id',
        loadChildren: './modules/manage-sorting/manage-sorting.module#ManageSortingModule'
      },
      {
        path: 'package',
        loadChildren: './modules/manage-sorting/manage-sorting.module#ManageSortingModule'
      },
      {
        path: 'question/:id',
        loadChildren: './modules/manage-sorting/manage-sorting.module#ManageSortingModule'
      }
    ]
  },
  {
    path: 'codegeneration',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage/:id',
        loadChildren: './modules/manage-codegeneration/manage-codegeneration.module#ManageCodeGenerationModule'
      }
    ]
  },
  {
    path: 'codegeneration',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create/:id',
        loadChildren: './modules/create-codegeneration/create-codegeneration.module#CreateCodeGenerationModule'
      }
    ]
  },
  {
    path: 'chapter',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create/:cid',
        loadChildren: './modules/create-chapter/create-chapter.module#CreateChapterModule'
      },
      {
        path: 'edit/:cid/:id',
        loadChildren: './modules/create-chapter/create-chapter.module#CreateChapterModule'
      },
      {
        path: 'content/videos/:cid/:id',
        loadChildren: './modules/create-chapter-content/create-chapter-content.module#CreateChapterContentModule'
      },
      {
        path: 'content/stories/:cid/:id',
        loadChildren: './modules/create-chapter-story/create-chapter-story.module#CreateChapterStoryModule'
      }
    ]
  },
  {
    path: 'chapter',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage/:id',
        loadChildren: './modules/manage-chapter/manage-chapter.module#ManageChapterModule'
      }
    ]
  },
  {
    path: 'course',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-course/create-course.module#CreateCourseModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-course/create-course.module#CreateCourseModule'
      }
    ]
  },
  {
    path: 'course',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-course/manage-course.module#ManageCourseModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-course/create-course.module#CreateCourseModule'
      }
    ]
  },
  {
    path: 'quiz',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-quiz/create-quiz.module#CreateQuizModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-quiz/create-quiz.module#CreateQuizModule'
      }
    ]
  },
  {
    path: 'quiz',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-quiz/manage-quiz.module#ManageQuizModule'
      }
    ]
  },
  {
    path: 'user',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/registration/registration.module#RegistrationModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/registration/registration.module#RegistrationModule'
      }
      , {
        path: 'summary/:id',
        loadChildren: './modules/user-summary/user-summary.module#UserSummaryModule'
      },
      {
        path: 'import',
        loadChildren: './modules/import-user/import-user.module#ImportUserModule'
      }
    ]
  },
  {
    path: 'user',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-users/manage-users.module#ManageUsersModule'
      }
    ]
  },
  {
    path: 'question',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage/:id',
        loadChildren: './modules/manage-question/manage-question.module#ManageQuestionModule'
      }
    ]
  }, {
    path: 'question',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create/:id',
        loadChildren: './modules/create-question/create-question.module#CreateQuestionModule'
      },
      {
        path: 'edit/:id/:qid',
        loadChildren: './modules/create-question/create-question.module#CreateQuestionModule'
      }
    ]
  },
  {
    path: 'store',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'create',
        loadChildren: './modules/create-store/create-store.module#CreateStoreModule'
      }, {
        path: 'edit/:id',
        loadChildren: './modules/create-store/create-store.module#CreateStoreModule'
      }
    ]
  },
  {
    path: 'store',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'manage',
        loadChildren: './modules/manage-store/manage-store.module#ManageStoreModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'settings',
        loadChildren: './modules/default-settings/default-settings.module#DefaultSettingsModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'wa-sentitems',
        loadChildren: './modules/wa-sentitem/wa-sentitem.module#WaSentItemModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'wa-notification',
        loadChildren: './modules/wa-notification/wa-notification.module#WaNotificationModule'
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'dynamic-linking',
        loadChildren: './modules/dynamic-linking/dynamic-link.module#DynamiclinkModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

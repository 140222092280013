import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule }  from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@app/shared';
import { APP_CONFIG, AppConfig } from '@app/config';
import { CoreModule } from '@app/core';
import { AppComponent } from './app.component';
import { PageLoaderComponent } from './shared/components/page-loader/page-loader.component';
import { BroadcastService } from '@app/core/services/broadcast.service';
import { ErrorService } from './core/services/error.service';



@NgModule({
  declarations: [AppComponent,PageLoaderComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    ToasterModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,    
  ],
  providers: [
    BroadcastService,
    ToasterService,
    {
      provide: APP_CONFIG,
      useValue: AppConfig
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
     },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

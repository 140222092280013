function callFroalaEditorWithAllOption() {

  var url = localStorage.getItem('url');
  var token=localStorage.getItem('accesstoken');
  $.FroalaEditor.DefineIcon('insert', { NAME: 'plus' });
  $.FroalaEditor.RegisterCommand('insert', {
    title: 'Add tooltip',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      var selected = this.html.getSelected();
      if (selected.length == 0) {
        alert("please select text first");
        return;
      }

      $("#tooltipContent").find('.fr-view').html('');
      try {
        if ($(selected).find('[data-content]').length > 0) {
          $("#tooltipContent").find('.fr-view').html(unescape($(selected).find('[data-content]').attr('data-content')));
        } else {
          var dataView = $(selected).attr('data-content');
          if (typeof (dataView) != "undefined") {
            $("#tooltipContent").find('.fr-view').html(unescape(dataView));
          }
        }
      } catch{

      }


      doConfirm("Are you sure?", function yes() {
        var textareaContent = $("#tooltipContent").find('.fr-view').html();
        var selectedText = selected;

        try {
          selectedText = $(selected).text();
        } catch{
        }
        if (selectedText == "")
          selectedText = selected;

        $('#tooltipcontent').attr("data-tooltip", '');
        $('#tooltipcontent').attr("data-content", escape(textareaContent));
        var id = new Date().getTime();
        $('#tooltipcontent').attr("data-id", 'tooltip-' + id);
        $('#tooltipcontent').attr("title", selectedText.replace(/"/g, "'"));
        $('#tooltipcontent').html(selectedText);
        $('#tooltipcontent').removeAttr("id");

      }, function no() {
        if(typeof(this.html)!='undefined'){
          this.html.insert(selected);
        }

      });
      this.html.insert('<span id="tooltipcontent" data-toggle="popover"></span>');

    }
  });

  function doConfirm(msg, yesFn, noFn) {
    var confirmBox = $("#confirmBox");
    //confirmBox.find("#tooltipContent").text(msg);
    confirmBox.find(".yes,.no").unbind().click(function () {
      confirmBox.hide();
    });
    confirmBox.find(".yes").click(yesFn);
    confirmBox.find(".no").click(noFn);
    confirmBox.show();
  }
  $('[data-editor-main]').each(function () {
    $(this).froalaEditor({
      toolbarInline: false,
      toolbarSticky: false,
      toolbarButtons: ["fullscreen", "bold", "italic",
        "strikeThrough", "underline", "fontFamily", "fontSize", "color",
        "inlineClass",  "paragraphStyle", "lineHeight", "paragraphFormat",
        "align", "formatOL", "formatUL", "outdent", "insertHR", "insertTable", "insertImage", "insertVideo",
        "specialCharacters", "clearFormatting"],
      placeholderText: '',
      toolbarInline: false,
      toolbarSticky: false,
      fontFamily: {
        "ML-TTIndulekhaNormal": 'ML-TTIndulekhaNormal',
        "ML-TTKarthikaNormal": "ML-TTKarthikaNormal",
        "ML-TTRevathiNormal": "ML-TTRevathiNormal",
        "FML-TTRevathi":"FML-TTRevathi",
        "Verdana": 'Verdana',
        "Times New Roman": 'Times New Roman',
        "Tahoma": 'Tahoma',
        "Impact": 'Impact',
        "Georgia": 'Georgia',
        "Arial": 'Arial',
        "Bookman Old Style normal":"Bookman Old Style normal",
        "Bookman Old Style bold-normal":"Bookman Old Style bold-normal",
        "Bookman Old Style italic":"Bookman Old Style italic",
        "Bookman Old Style bold-italic":"Bookman Old Style bold-italic"
      },
      fontSize: ['8', '9', '10', '11', '13', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
      fontFamilySelection: true,

      tableCellStyles: {
        "fr-highlighted": "Highlighted",
        "fr-thick": "Thick",
        "febordertop": 'Border top',
        "feborderbottom": 'Border bottom',
        "feborderleft": 'Border left',
        "feborderright": 'Border right',
        "fenoborder": 'No border',
        "fefullborder": 'Full border',
      },
      quickInsertButtons: [],
      // Set the image upload parameter.
      imageUploadParam: 'file',
      // Set the video upload parameter.
      videoUploadParam: 'video_param',

      // Set the image upload URL.
      imageUploadURL: url + 'upload?source=editor&access='+token,
      // Set the video upload URL.
      videoUploadURL: url + 'upload?source=editor&access='+token,

      // Additional upload params.
      //imageUploadParams: {id: 'my_editor'},

      // Set request type.
      imageUploadMethod: 'POST',

      // Set request type.
      videoUploadMethod: 'POST',

      videoAllowedProviders:['.*'],
      // Set max image size to 25MB.
      imageMaxSize: 25 * 1024 * 1024,
      // Set max video size to 250MB.
      videoMaxSize: 250 * 1024 * 1024,

      // Allow to upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],

      // Allow to upload MP4, WEBM and OGG
      videoAllowedTypes: ['webm', 'mp4', 'ogg'],

      events: {
        'image.beforeUpload': function (images) {
          // Return false if you want to stop the image upload.
        },
        'image.uploaded': function (response) {
          // Image was uploaded to the server.
        },
        'image.inserted': function ($img, response) {
          // Image was inserted in the editor.
        },
        'image.replaced': function ($img, response) {
          // Image was replaced in the editor.
        },
        'image.error': function (error, response) {

          // Bad link.
          if (error.code == 1) { }

          // No link in upload response.
          else if (error.code == 2) { }

          // Error during image upload.
          else if (error.code == 3) { }

          // Parsing response failed.
          else if (error.code == 4) { }

          // Image too text-large.
          else if (error.code == 5) { }

          // Invalid image type.
          else if (error.code == 6) { }

          // Image can be uploaded only to same domain in IE 8 and IE 9.
          else if (error.code == 7) { }

          // Response contains the original server response to the request if available.
        },
        'video.beforeUpload': function (videos) {
          // Return false if you want to stop the video upload.
        },
        'video.uploaded': function (response) {
          // Video was uploaded to the server.
        },
        'video.inserted': function ($img, response) {
          // Video was inserted in the editor.
        },
        'video.replaced': function ($img, response) {
          // Video was replaced in the editor.
        },
        'video.error': function (error, response) {
          // Bad link.
          if (error.code == 1) { }

          // No link in upload response.
          else if (error.code == 2) { }

          // Error during video upload.
          else if (error.code == 3) { }

          // Parsing response failed.
          else if (error.code == 4) { }

          // Video too text-large.
          else if (error.code == 5) { }

          // Invalid video type.
          else if (error.code == 6) { }

          // Video can be uploaded only to same domain in IE 8 and IE 9.
          else if (error.code == 7) { }
        }
      }
    });
  });

  $('[data-editor]').each(function () {
    $(this).froalaEditor({
      toolbarInline: false,
      toolbarSticky: false,
      toolbarButtons: ["fullscreen", "bold", "italic", "strikeThrough", "underline",
        "subscript", "superscript", "fontFamily", "fontSize", "color", "inlineClass",
        "paragraphStyle", "lineHeight", "paragraphFormat", "align", "formatOL",
        "formatUL", "outdent", "indent", "quote", "insertHR", "html", "undo", "redo", "insertTable",
        "insertLink", "insertImage", "insertVideo", "specialCharacters", "clearFormatting", '|', 'insert', 'insertAudio', '|', 'insert', 'video'],
      placeholderText: '',
      fontFamily: {
        "ML-TTIndulekhaNormal": 'ML-TTIndulekhaNormal',
        "ML-TTKarthikaNormal": "ML-TTKarthikaNormal",
        "ML-TTRevathiNormal": "ML-TTRevathiNormal",
        "FML-TTRevathi":"FML-TTRevathi",
        "Verdana": 'Verdana',
        "Times New Roman": 'Times New Roman',
        "Tahoma": 'Tahoma',
        "Impact": 'Impact',
        "Georgia": 'Georgia',
        "Arial": 'Arial',
        "Bookman Old Style normal":"Bookman Old Style normal",
        "Bookman Old Style bold-normal":"Bookman Old Style bold-normal",
        "Bookman Old Style italic":"Bookman Old Style italic",
        "Bookman Old Style bold-italic":"Bookman Old Style bold-italic"


      },
      fontSize: ['8', '9', '10', '11', '13', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
      fontFamilySelection: true,
      inlineClasses: {
        'fr-class-kar-05': 'Karthika Style 1',
        'fr-class-kar-0': 'Karthika Style 2',
        'fr-class-rev-05': 'Revathi Style 1',
        'fr-class-rev-0': 'Revathi Style 1',
      },
      tableCellStyles: {
        "fr-highlighted": "Highlighted",
        "fr-thick": "Thick",
        "febordertop": 'Border top',
        "feborderbottom": 'Border bottom',
        "feborderleft": 'Border left',
        "feborderright": 'Border right',
        "fenoborder": 'No border',
        "fefullborder": 'Full border',
      },
      quickInsertButtons: [],
      // Set the image upload parameter.
      imageUploadParam: 'file',
      // Set the video upload parameter.
      videoUploadParam: 'file',

      // Set the image upload URL.
      imageUploadURL: url + 'upload?source=editor&access='+token,
      // Set the video upload URL.
      videoUploadURL: url + 'upload?source=editor&access='+token,

      // Additional upload params.
      //imageUploadParams: {id: 'my_editor'},

      // Set request type.
      imageUploadMethod: 'POST',

      // Set request type.
      videoUploadMethod: 'POST',

      videoAllowedProviders:['.*'],
      // Set max image size to 25MB.
      imageMaxSize: 25 * 1024 * 1024,
      // Set max video size to 250MB.
      videoMaxSize: 250 * 1024 * 1024,

      // Allow to upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],

      // Allow to upload MP4, WEBM and OGG
      videoAllowedTypes: ['webm', 'mp4', 'ogg'],

      events: {
        'image.beforeUpload': function (images) {
          // Return false if you want to stop the image upload.
        },
        'image.uploaded': function (response) {
          // Image was uploaded to the server.
        },
        'image.inserted': function ($img, response) {
          // Image was inserted in the editor.
        },
        'image.replaced': function ($img, response) {
          // Image was replaced in the editor.
        },
        'image.error': function (error, response) {

          // Bad link.
          if (error.code == 1) { }

          // No link in upload response.
          else if (error.code == 2) { }

          // Error during image upload.
          else if (error.code == 3) { }

          // Parsing response failed.
          else if (error.code == 4) { }

          // Image too text-large.
          else if (error.code == 5) { }

          // Invalid image type.
          else if (error.code == 6) { }

          // Image can be uploaded only to same domain in IE 8 and IE 9.
          else if (error.code == 7) { }

          // Response contains the original server response to the request if available.
        },
        'video.beforeUpload': function (videos) {
          // Return false if you want to stop the video upload.
        },
        'video.uploaded': function (response) {
          // Video was uploaded to the server.
        },
        'video.inserted': function ($img, response) {
          // Video was inserted in the editor.
        },
        'video.replaced': function ($img, response) {
          // Video was replaced in the editor.
        },
        'video.error': function (error, response) {
          // Bad link.
          if (error.code == 1) { }

          // No link in upload response.
          else if (error.code == 2) { }

          // Error during video upload.
          else if (error.code == 3) { }

          // Parsing response failed.
          else if (error.code == 4) { }

          // Video too text-large.
          else if (error.code == 5) { }

          // Invalid video type.
          else if (error.code == 6) { }

          // Video can be uploaded only to same domain in IE 8 and IE 9.
          else if (error.code == 7) { }
        }
      }
    });
  });


}
function ExcelExport(id, name) {
  var table = $(id);
  if (table && table.length) {
    var preserveColors = (table.hasClass('table2excel_with_colors') ? true : false);
    $(table).table2excel({
      exclude: ".noExl",
      name: name,
      filename: name + ".xls",
      fileext: ".xls",
      exclude_img: true,
      exclude_links: true,
      exclude_inputs: true,
      preserveColors: preserveColors
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '@app/core/services/broadcast.service';
@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.css']
})
export class PageLoaderComponent  {


  public showLoader: boolean;
  /**Contructor */
  constructor(private _broadcastService: BroadcastService) {
    this.showLoader = false;
    this._broadcastService.loaderSource.subscribe((showLoader) => {
      if (showLoader) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }

    });
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  @Input() data = [];
  @Input() selectedItemsInput = [];
  //@Input() dropdownSettingsInput = {};
  @Output() seletedItem : EventEmitter<any> = new EventEmitter();

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  ngOnInit() {
    this.dropdownList = this.data;
    this.selectedItems = this.selectedItemsInput;
    //this.dropdownSettings = this.dropdownSettingsInput;
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
  }
 
  onItemSelect(item: any) {
    this.seletedItem.emit(item)
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
    this.seletedItem.emit(items)
  }
}

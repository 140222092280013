import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core';
import { environment } from '@env/environment';
import { GlobalConfig } from '@env/global-config';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: any;
  role: any;
  currentMenu: any;
  isMobileMenu: boolean = false;
  constructor(private router: Router, private _apiService: ApiService) { }
  ngOnInit() {
    localStorage.setItem('url', environment.apiUrl);
    this.username = localStorage.getItem('username');
    this.role = localStorage.getItem('role');
    var url = this.router.url.replace("/", "");
    if (url.indexOf("/") > -1) {
      url = url.substring(0, url.indexOf("/"));
    }
    if (url == "question") {
      url = "quiz";
    } else if (url == "chapter") {
      url = "course";
    }
    else if (url == "enrollmentdetails") {
      url = "userenrollment";
    }
    else if (url == "codegeneration") {
      url = "accesscode";
    }
    this.currentMenu = url;

  }
  public logout() {
    this._apiService.get(GlobalConfig.Urls.login).subscribe((val) => {
      if (val.status == 200) {
        localStorage.clear();
        this.router.navigateByUrl('/');
      }
    });
  }
  public mobileMenu() {
    this.isMobileMenu = !this.isMobileMenu;
  }
}

export const GlobalConfig = {
  Urls: {
    ordersList: "user/orders",
    login: "auth/login",
    registration: "user/create",
    checkmail: "user/checkemail/",
    saveError: "public/error-log",

    forgot_password: "user/userforgot-password/",
    //forgot_password: "user/forgot-password/", old
    update_password: "user/user-update-forgot-password",
    // update_password: "user/update-forgot-password",//old
    //Subject
    subjectList: "subject/list",
    subjectCreate: "subject/create",
    subjectDetails: "subject/details/",
    subjectUpdate: "subject/update",
    subjectDelete: "subject/delete",



    //Previous Question
    //previousQuestionList: "previousQuestions",
    previousQuestionList: "previousQuestions/adminview",
    previousQuestionCreate: "previousQuestions",
    previousQuestionUpdate: "previousQuestions/update",
    previousQuestionEdit: "previousQuestions/",



    //Course
    courseList: "course/list",
    bookList: "course/list?dist=true",
    courseCreate: "course/create",
    bookCreate: "course/create",
    courseDetails: "course/details/",
    courseUpdate: "course/update",
    bookUpdate: "course/update",
    courseDelete: "course/delete",
    //Chapter
    //chapterList:"public/list-chapters/",
    chapterList: "course/list-chapters/",
    chapterCreate: "chapter/create",
    chapterDetail: "chapter/details/",
    chapterUpdate: "chapter/update",
    chapterDelete: "chapter/delete",
    chapterReorder: "chapter/reorder",
    chapterClone: "admin/clone/chapter",
    chapterCreateContentVideo: "content/create/video/lesson",
    chapterUpdateContentVideo: "content/create/video/lesson",
    chapterListContentVideo: "content/video/",
    chapterDeleteContentVideo: "content/video/",

    chapterListContentStory: "content/story/",
    chapterCreateContentStory: "content/create/story/lesson",
    chapterUpdateContentStory: "content/story/",
    chapterDeleteContentStory: "content/story/",

    chapterReorderVideo: "content/order/video",
    chapterReorderStory: "content/order/stories",

    //Quiz
    quizList: "quiz/list",
    quizCreate: "quiz/create",
    quizDetails: "quiz/details/",
    quizUpdate: "quiz/update",
    quizDelete: "quiz/delete",
    quizUploadFile : "quiz/uploadFile",
    quizClone: "admin/clone/quiz",
    //Question
    questionList: "quiz/questions/list/",
    questionCreate: "quiz/questions/create",
    questionDetails: "quiz/details/",
    questionUpdate: "quiz/questions/edit",
    questionDelete: "quiz/questions/delete",
    questionReorder: "quiz/question/reorder",
    questionClone: "admin/clone/question",

    list_entrollment: "course/list-entrollment",
    list_course_users: "course/list-course-users",
    list_package_users: "course/list-package-users",
    accesscode_store_list: "accesscode/storelist",
    sellpackage: "Quiz/sellpackage",
    //AccessCode
    accesscode_create: "accesscode/create",
    accesscode_view: "accesscode/view/",
    accesscode_update: "accesscode/update",
    accesscode_delete: "accesscode/delete",
    accesscode_delete_details: "accesscode/delete/code/",
    accesscode_list: "accesscode/list",
    accesscode_codegenerator: "accesscode/codegenerator",
    accesscode_viewdetails: "accesscode/accesscodeview/",
    //User
    userList: "user/list",
    userDelete: "user/delete",
    userUpdate: "user/update",
    userView: "user/view/",
    blockUser: "user/blockUser",
    mycourses: "user/mycourses",
    addMaterial: "admin/add/material",

    public_profile: "user/get-profile-details",
    update_profile_details: "user/update-profile-details?adminMind=true",
    import_user: "user/importUser",
    change_password: "user/change-password",
    paymentTransfer: "user/list-payment-transfer",
    paymentDelete: "user/deleteJob/",
    payment: "user/list-payment",
    //Store
    storeCreate: "store/create",
    storeList: "store/list",
    storeDelete: "store/delete",
    storeUpdate: "store/update",
    storeImport: "store/import",
    storeView: "store/view/",

    archiveCourse: "course/archive-course",
    archivePackage: "quiz/archive-quiz",
    notificationCreate: "notification/create",
    notificationList: "notification/list",
    ourSchool: "public/our-schools",

    videoUpload: "video-lessons?auth=",
    settingsDetail: "setting/listSettings",
    settingsUpdate: "setting/setSettings",

    waUserList: "user/phoneNumberList",
    templateList: "whatsapp/listTemplates",
    templateDetail:"whatsapp/templateDetail/",
    messageCreate:"whatsapp/createMessage",
    messageListCreate:"whatsapp/createMessageList",
    sentItemList:"whatsapp/sentItems",
    getSchoollist:"user/getSchools",

    copyStories:"admin/copystory",
    deeplinking:"deeplinkNotification/create",
    deeplinklist:"deeplinkNotification/get"

  }
}


//ng build --prod --aot=false --build-optimizer=false
let link = 'https://stagingapi.eksalar.com/'
//let link = 'http://localhost:3000/'

export const environment = {
  production: false,
  env: 'dev',
  timezone: '?timeZone=Asia/Calcutta',
  apiUrl: link, /* Change this url */
  //frontEndUrl: "http://localhost:4205/",
  frontEndUrl: "https://eksalar.com/",
  Routers: {

  },




};

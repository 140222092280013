import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { PublicLayoutComponent } from './components/layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './components/layout/private-layout/private-layout.component';
import { AppSidebar } from './components/layout/sidemenu/sidemenu.component';
import { FileUploadComponent } from './components/layout/file-upload/file-upload.component';
import { MultiSelectComponent } from './components/layout/multi-select/multi-select.component';

import { DataLoadResolve } from './components/layout/multi-select/multi-select.resolve';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: localStorage.getItem('url') + 'upload',
  timeout: 1800000,
  //url: 'http://api.eksalar.com/upload',
  headers: { 'x-auth': localStorage.getItem('accesstoken') },
  maxFilesize: 50,
  acceptedFiles: 'video/*,image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    AppSidebar,
    FileUploadComponent,
    MultiSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    DropzoneModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    AppSidebar,
    FileUploadComponent,
    MultiSelectComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    DataLoadResolve
  ],
})
export class SharedModule { }
